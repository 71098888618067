<p-dialog header="Select New Job Owner"
          [(visible)]="isVisible"
          [responsive]="true"
          [draggable]="false"
          modal="true"
          styleClass="dialog-custom"
          [style]="{width: '400px', height: '200px'}">

          <div class="h-100 d-flex flex-column justify-content-between">
            <div class="pt-4 px-3">
              <p-autoComplete appendTo="body"
                              field="display"
                              placeholder="Select Job Owner"
                              [(ngModel)]="result"
                              [suggestions]="members"
                              [baseZIndex]="10000"
                              (completeMethod)="onComplete($event.query)"
                              (onClear)="onClear()">
              </p-autoComplete>
              <div class="pt-3">
                <field-error-display [displayError]="error" [errorMsg]="error">
                </field-error-display>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <div class="btn cancel-btn" (click)="close()">Cancel</div>
                <div>
                  <button type="button"
                          class="btn btn-primary button-save"
                          (click)="changeJobOwner()"
                          [disabled]="error || !result?.networkId">
                          SAVE
                  </button>
                </div>
            </div>
          </div>

</p-dialog>