export class CasingEquipmentModel {
  id: string;
  isCentralizers: boolean;
  isPlugsDartBalls: boolean;
  isFloatEquipment: boolean;
  isPlugContainers: boolean;
  minIDBoreHole: number;
  minIDPipe: number;
  maxODCasing: number;
  maxODPlug: number;
  lowestTempRating: number;
  casingConnectionThread: string;
  casingEquipmentConnectionThread: string;
  maxClientPressureRating: number;
  lowestPressureRating: number;
  isCompatibleAllEquipment: boolean;
  compatibleAllEquipmentComment: string;
  isCompatibleJobPlan: boolean;
  compatibleJobPlanComment: string;
  lowestPumpRate: number;
  maxPumpRate: number;
  maxPumpRateUserDefined: number;
  commentPumpRate: string;
  volume: number;
  totalPlannedVolume: number;
  totalPlannedVolumeUserDefined: number;
  commentVolume: string;
  lowestPressureRate: number;
  maxPlannedPressureRate: number;
  commentPressureRate: string;

  // Equipment
  isAllEquipmentPrepared: boolean;
  allEquipmentPreparedComment: string;
  wellConnectionSize: number;
  wellConnectionThreads: string;
  plugContainerConnectionSize: number;
  requiredCrossoverConnections: string;
  isFunctionTestPerformed: boolean;
  isDartCompatible: boolean;
  dartCompatibilityComment: string;
  isIDODCompatibilityConfirmed: boolean;

  constructor() {
    this.id = null;
    this.isCentralizers = false;
    this.isPlugsDartBalls = false;
    this.isFloatEquipment = false;
    this.isPlugContainers = false;
    this.minIDBoreHole = null;
    this.minIDPipe = null;
    this.maxODCasing = null;
    this.maxODPlug = null;
    this.lowestTempRating = null;
    this.casingConnectionThread = null;
    this.casingEquipmentConnectionThread = null;
    this.maxClientPressureRating = null;
    this.lowestPressureRating = null;
    this.isCompatibleAllEquipment = false;
    this.compatibleAllEquipmentComment = null;
    this.isCompatibleJobPlan = false;
    this.compatibleJobPlanComment = null;
    this.lowestPumpRate = null;
    this.maxPumpRate = null;
    this.maxPumpRateUserDefined = null;
    this.commentPumpRate = null;
    this.volume = null;
    this.totalPlannedVolume = null;
    this.totalPlannedVolumeUserDefined = null;
    this.commentVolume = null;
    this.lowestPressureRate = null;
    this.maxPlannedPressureRate = null;
    this.commentPressureRate = null;

    // Equipment
    this.isAllEquipmentPrepared = false;
    this.allEquipmentPreparedComment = null;
    this.wellConnectionSize = null;
    this.wellConnectionThreads = null;
    this.plugContainerConnectionSize = null;
    this.requiredCrossoverConnections = null;
    this.isFunctionTestPerformed = false;
    this.isDartCompatible = null;
    this.dartCompatibilityComment = null;
    this.isIDODCompatibilityConfirmed = false;
  }
}
