<div class="sidebar-content-title">
  <span class="badge-number">2</span>
  <h4>Download HDF files</h4>
</div>
<div class="sidebar-content-desc">
  {{sidebarDesc}}
</div>
<div *ngIf="orderedGroupedFileList.length > 0" class="sidebar-select-all">
  <p-checkbox (onChange)="checkAllFilesSelected($event)" name="selectAll" [disabled]="selectAllDisabled"
    [(ngModel)]="selectAllChecked" binary="true" label="Select all HDF files"></p-checkbox>
</div>
<div class="document-group-list" *ngIf="orderedGroupedFileList.length>0">

  <p-accordion [multiple]="true">
    <div *ngFor="let group of orderedGroupedFileList">
      <p-accordionTab header="{{group.groupName}}" [selected]="true" *ngIf="group.items.length > 0">
        <div>Select File to Download</div>
        <div>
          <span *ngIf="group.groupName===hdfDocGroupName.ControlPoint3">{{jsqaExistedMessage}}</span>
        </div>
        <table class="table file-list">
          <thead>
            <tr>
              <th>File Name</th>
              <th style="width: 190px">
                <span>{{group.groupName===hdfDocGroupName.iCemDesignRealtime? 'iCem File Type' : 'HDF File
                  Type'}}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of group.items">
              <td>
                <div class="pcheckbox-wraplabel">
                  <p-checkbox (onChange)="checkHasFilesSelected($event)" name="{{file.id}}"
                    [disabled]="file.isDownloaded" binary="true" label="{{file.attachmentName}}"
                    [(ngModel)]="file.isSelected" inputId="{{file.id}}"></p-checkbox>
                </div>
              </td>
              <td>
                <p-dropdown [disabled]="file.isDownloaded" [ngModel]="file.attachmentType" (ngModelChange)="attachmentTypeChanged($event, file)" [options]="hdfFileTypes">
                </p-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </p-accordionTab>
    </div>
  </p-accordion>
</div>