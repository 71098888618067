import { Component, OnInit, Injector, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ApplicationStateService, FluidService } from 'libs/shared/services';
import { UnitType } from 'libs/constants';
import { FormControlContainer } from 'libs/ui';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { createMudParameterFromGroup } from './mud-parameters.form.factory';
import { MudTypeService } from '../../services';
import { PDropdownDataSanitizerHelper } from '../../../shared/helpers';
import { filter, map, skip } from 'rxjs/operators';
import { FluidModel, IMudType } from 'libs/models';
import { CustomValidators } from '../../../shared/validators';
import { isFormInvalid } from 'libs/helpers';
import { JobModalManagementService } from '../../../../../../../libs/ui/services/job-modal-management.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mud-parameters',
  templateUrl: './mud-parameters.component.html',
  styleUrls: ['./mud-parameters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MudParametersComponent extends FormControlContainer implements OnInit, OnDestroy {

  isMudParametersVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onClose: Observable<any> = this.isMudParametersVisible.pipe(skip(1), filter(isVisible => !isVisible));

  UnitType = UnitType;
  formMudParameter: UntypedFormGroup;
  tempFormMudParameter: UntypedFormGroup;
  mudTypes: IMudType[];
  mudParameter: string;
  order: number;
  isPumpDefault: boolean;
  identifier: string;
  fluid: FluidModel;
  data: any;
  
  errorMessages = 'Mud Density is required field.';

  subscriptions: Subscription = new Subscription();

  constructor(
    protected injector: Injector,
    private fb: UntypedFormBuilder,
    private applicationStateService: ApplicationStateService,
    private jobModalManagementService: JobModalManagementService,
    private mudTypeService: MudTypeService,
    private cd: ChangeDetectorRef,
    private fluidService: FluidService,
  ) {
    super(injector);
    this.formControl = this.formMudParameter = createMudParameterFromGroup(this.fb);
  }

  ngOnInit() {

    this.formMudParameter = this.data.form;
    this.order = this.data.order;
    this.isPumpDefault = this.data.isPumpDefault;
    this.identifier = this.data.identifier;
    this.fluid = this.data.fluid;
    this.isMudParametersVisible.next(true);

    this.subscriptions.add(
      this.mudTypeService.getMudTypes()
      .pipe(map(PDropdownDataSanitizerHelper('typeName', 'id')))
      .subscribe((response: IMudType[]) => this.mudTypes = response)
    );

    this.subscriptions.add(
      this.jobModalManagementService.afterhideModal$
      .subscribe(() => { this.closeMud(); })
    )

    this.formMudParameter.valueChanges.subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.isMudParametersVisible.complete();
    this.isMudParametersVisible = null;
    this.subscriptions.unsubscribe();
  }

  updateMud() {
    // Update Mud type name to display on UI
    this.formMudParameter.controls.mudDensity.updateValueAndValidity();
    this.markAsTouched();
    if (!this.isInvalid()) {

      const mudType = this.mudTypes.find((x: any) => x.value === this.formMudParameter.value.mudTypeId) as any;

      if (mudType) {
        this.formMudParameter.controls.typeName.setValue(mudType.label);
      } else {
        this.formMudParameter.controls.typeName.setValue(null);
      }

      const mudData = this.formMudParameter.getRawValue();

      this.fluid.density = mudData.mudDensity;
      this.fluid.displayName = this.fluidService.buildDisplayName(this.fluid, []);
      const typeName = mudData.typeName ? `, ${mudData.typeName}` : '';
      const name = mudData.mudName ? `, ${mudData.mudName}` : '';
      this.fluid.name = `${this.fluidService.buildDensityDesc(this.fluid)}${typeName}${name}`;

      this.applicationStateService.updateMudData$.next({
        mudParameterData: mudData,
        order: this.order,
        isPumpDefault: this.isPumpDefault
      });

      this.applicationStateService.mudAsFluid$.next({
        identifier: this.identifier,
        fluid: this.fluid
      });
      
      this.isMudParametersVisible.next(false);
    }

    this.cd.detectChanges();
  }

  closeMud() {
    if(this.tempFormMudParameter && this.tempFormMudParameter.value ) {
      this.formMudParameter.patchValue(this.tempFormMudParameter.value);
      this.isMudParametersVisible.next(false);
    }
  }

  setValidator() {
    this.formMudParameter.controls.mudDensity.setValidators(CustomValidators.requiredWithTrimming);
    
    // Clone form for tracking data when valuechange
    this.tempFormMudParameter = { ...this.formMudParameter } as UntypedFormGroup;
  }

  resetParams() {
    this.formMudParameter.controls.mudDensity.clearValidators();
    this.formMudParameter.controls.mudDensity.updateValueAndValidity();
  }

  isInvalid(): boolean {
    return this.formMudParameter && isFormInvalid(this.formMudParameter);
  }

}
