import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  AdminService,
  CacheService,
  CollectionService,
  ComunicationLogService,
  CustomerInformationService,
  CustomerService,
  EventHubService,
  FileService,
  FluidService,
  GroupService,
  HttpCommanderService,
  HttpService,
  IfactSearchService,
  IfactService,
  JobStateService,
  JobService,
  JobsService,
  JobTypeService,
  MasterDataService,
  MaterialService,
  ApplicationStateService,
  ReferenceService,
  RigService,
  RoleService,
  SessionService,
  SettingService,
  UserSettingService,
  WellService,
  WellsService,
  SAPMaterialMappingService,
  JobTechnicalService,
  CountryService,
  ContractService,
  HdfService,
  SaleOrderNumberService,
  MemberSearchService,
  KPIService,
  ISAPMaterialProductService,
  ApplicationInsightsService,
  ControlPointService2,
  BaService,
  ChemicalSearchService
} from './services';
import { UIModule } from 'libs/ui';
import { DropdownModule } from 'primeng/dropdown';
import { JobCasingEquipmentService } from './services/job-casing-equipment.service';
import { PlantManagementService } from './services/plant-management.service';
import { PumpScheduleStateFactory } from 'apps/vida/src/modules/pump/state/state-factory';
import { PumpScheduleFormManager } from 'apps/vida/src/modules/pump/form-manager';
const services = [
  AdminService,
  CacheService,
  CollectionService,
  ComunicationLogService,
  CustomerInformationService,
  CustomerService,
  EventHubService,
  FileService,
  FluidService,
  GroupService,
  HttpCommanderService,
  HttpService,
  IfactSearchService,
  IfactService,
  JobStateService,
  JobService,
  JobsService,
  JobTypeService,
  JobTechnicalService,
  CountryService,
  ContractService,
  SaleOrderNumberService,
  HdfService,
  MasterDataService,
  MaterialService,
  ApplicationStateService,
  ReferenceService,
  RigService,
  RoleService,
  SAPMaterialMappingService,
  SessionService,
  SettingService,
  UserSettingService,
  WellService,
  WellsService,
  MemberSearchService,
  KPIService,
  ISAPMaterialProductService,
  ApplicationInsightsService,
  JobCasingEquipmentService,
  ChemicalSearchService,
  ControlPointService2,
  PlantManagementService,
  BaService,
  PumpScheduleStateFactory,
  PumpScheduleFormManager
];

@NgModule({
  imports: [
    DropdownModule,
    UIModule
  ]
})
export class VIDASharedModule {
  static forRoot(): ModuleWithProviders<VIDASharedModule> {
    return {
      ngModule: VIDASharedModule,
      providers: [...services]
    };
  }
}
