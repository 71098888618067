import {
  HUNDRED_HOURS_IN_MS,
  TEN_HOURS_IN_MS,
  THOUSAND_HOURS_IN_MS,
} from '../../apps/vida/src/modules/ifacts-visualizer/constants';


export class TimeFormatter {

    public static msToTimeString(ms: any, maxValueMs:any | null = null): string {

        if (!ms) {
          if (parseInt(maxValueMs) > THOUSAND_HOURS_IN_MS) return '0000:00'
          if (parseInt(maxValueMs) > HUNDRED_HOURS_IN_MS) return '000:00'
          if (parseInt(maxValueMs) > TEN_HOURS_IN_MS) return '00:00'
          return '00:00'
        }
        const [ hours, minutes] = this.getTimeString(ms);
        if(maxValueMs === null) return [hours, minutes].map(value => value < 10 ? '0' + value : value).join(':');
        const hoursWithZero = this.getLeadingZeroes(hours, parseInt(maxValueMs));
        const minutesWithLeadingZero = minutes < 10 ? '0' + minutes : minutes;
        return [hoursWithZero, minutesWithLeadingZero].join(':');
    }

    public static getTimeString(ms: any) {
      const sec = parseInt(ms, 10) / 1000;
      const hours = Math.floor(sec / 3600);
      const minutes = Math.floor(sec / 60) % 60;
      return [hours, minutes];
    }

    private static getLeadingZeroes(hours: number, maxValue: number) {

      if (maxValue > THOUSAND_HOURS_IN_MS) {
        if (hours < 10) return  '000' + hours;
        if (hours < 100) return '00' + hours;
        return hours;
      }

      if (maxValue > HUNDRED_HOURS_IN_MS) {
         if (hours < 10) return '00' + hours;
         if (hours < 100) return '0' + hours;
         return hours;
      }

      if (maxValue > TEN_HOURS_IN_MS) {
        return hours < 10 ? '0' + hours : hours;
      }
      return hours;
    }
}
