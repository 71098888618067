export class ChemicalSearchCriteriaModel {
    public materialFilter: string;
    public materialCriteria: string;
    
    public pcFilter: string;
    public pcCriteria: string;

    public isValid: boolean;

    public clone() : ChemicalSearchCriteriaModel {
        return {
            materialFilter: this.materialFilter,
            materialCriteria: this.materialCriteria,
            pcFilter: this.pcFilter,
            pcCriteria: this.pcCriteria,
            isValid: this.isValid
        } as ChemicalSearchCriteriaModel;
    }
}